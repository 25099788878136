"use strict";

var quoteform = document.getElementById("getaquote");

if (quoteform) {
  // Display the current tabnew

  var showTab = function showTab(n) {
    // This function will display the specified tabnew of the form...
    var x = document.getElementsByClassName("tabnew");
    x[n].style.display = "block";
    //... and fix the Previous/Next buttons:
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
      document.getElementById("nextBtn").innerHTML = "Select Options";
    } else if (n == 1) {
      document.getElementById("prevBtn").style.display = "inline";
      document.getElementById("nextBtn").innerHTML = "Select Products";
    } else if (n == 2) {
      document.getElementById("controls").classList.add("opacity-0");
      document.getElementById("submitBtn").classList.remove("opacity-0");
    }
    //... and run a function that will display the correct step indicator:
    fixStepIndicator(n);
  };

  var nextPrev = function nextPrev(n) {
    // This function will figure out which tabnew to display
    var x = document.getElementsByClassName("tabnew");
    // Exit the function if any field in the current tabnew is invalid:
    if (n == 1 && !validateForm()) return false;

    // Hide the current tabnew:
    x[currentTab].style.display = "none";
    // Increase or decrease the current tabnew by 1:
    currentTab = currentTab + n;
    // if you have reached the end of the form...
    if (currentTab >= x.length) {
      // ... the form gets submitted:
      console.log("end");
      //document.getElementById('regForm').submit();
    } else {
      // Otherwise, display the correct tabnew:
      showTab(currentTab);
    }
  };

  var validateForm = function validateForm() {
    // This function deals with validation of the form fields
    var x,
        y,
        i,
        valid = true;
    x = document.getElementsByClassName("tabnew");
    y = x[currentTab].getElementsByTagName("input");
    // A loop that checks every input field in the current tabnew:
    for (i = 0; i < y.length; i++) {
      // If a field is empty...
      if (y[i].value == "") {
        // add an "invalid" class to the field:
        y[i].className += " invalid";
        // and set the current valid status to false
        valid = false;
      }
    }
    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
      document.getElementsByClassName("step")[currentTab].className += " finish";
    }
    return valid; // return the valid status
  };

  var fixStepIndicator = function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i,
        x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    //... and adds the "active" class on the current step:
    x[n].className += " active";
  };

  var currentTab = 0; // Current tabnew is set to be the first tabnew (0)
  showTab(currentTab);
}
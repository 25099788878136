"use strict";

var $carouseldetail = $(".carouseldetail");
var $carouselnav = $(".carouselnav");

$carouseldetail.slick({
  rows: 0,
  slidesToShow: 5,
  arrows: false,
  draggable: false,
  useTransform: false,
  cssEase: "linear",
  variableWidth: true,
  responsive: [{
    breakpoint: 1000,
    settings: {
      slidesToShow: 4
    }
  }, {
    breakpoint: 940,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 900,
    settings: {
      slidesToShow: 5,
      centerMode: true
    }
  }, {
    breakpoint: 800,
    settings: {
      slidesToShow: 5,
      centerMode: true
    }
  }]
});

$carouselnav.slick({
  rows: 0,
  useTransform: false,
  prevArrow: ".btn-prev-product-carousel",
  nextArrow: ".btn-next-product-carousel",
  fade: true,
  asNavFor: $carouseldetail
});

$(".carouseldetail .item").on("click", function () {
  var index = $(this).attr("data-slick-index");
  $carouselnav.slick("slickGoTo", index);
});
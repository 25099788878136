"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");
    var burgerBtn = $(".burger-3");
    var subMenu = $(".submenuavailable");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
          burgerBtn.toggleClass("burger-active-3");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
          burgerBtn.toggleClass("burger-active-3");
        }
      });
    }

    subMenu.click(function () {
      event.preventDefault();
      $(this).parents("li").toggleClass("subactive");
      console.log("menu clicked");
    });
  },

  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  initTabs: function initTabs() {
    var tabs = $("#tabs-btns span");
    var tabContent = $("#tabs-content .tab-wrap-content-inner");

    $.each(tabContent, function (key, row) {
      var row = $(row);
      row.attr("data-tab-content", key);
      row.addClass("hidden");
    });

    $.each(tabs, function (key, row) {
      var row = $(row);
      row.attr("data-tab-btn", key);
      row.click(function () {
        var selected = $(this);
        tabs.removeClass("selected");
        selected.addClass("selected");
        tabContent.addClass("hidden");
        $("#tabs-content .tab-wrap-content-inner[data-tab-content=" + key + "]").removeClass("hidden").fadeIn();
      });

      if (key == 0) {
        row.trigger("click");
      }
    });
  },

  dropdown: function dropdown() {
    var parent = $("#mainnav > li > a.submenu");

    parent.click(function (event) {
      var active_tab_selector = $("#mainnav > li > a.submenu").attr("href");
      var actived_nav = $("#mainnav > li.open");
      var target_tab_selector = $(this).attr("id");
      //var target_tab_selector = ".home-security";
      console.log(target_tab_selector);

      event.preventDefault();
      $(this).parents("li").toggleClass("open");
      $(target_tab_selector).toggleClass("open");
    });

    // $(document).on("click", function(e){
    //   var width = $(window).width();
    //   var container = $('.menu-dropdown');

    //   console.log("clicked")

    // close menu whenever clicked outside of it

    //   if((container.has(e.target).length === 0) && (width > 960)) {
    //     $('.menu-dropdown.open').removeClass('open');
    //   }
    // });
  },

  appFeaturesCentered: function appFeaturesCentered() {
    var $block = $(".block_app_features_centered");
    if ($block.length) {
      // console.log("featured loaded");

      // Changing screenshots with fading animation
      var $screen = $(".features__phone_image");
      // console.log($screen);
      $("[data-screenshot]", $block).off("mouseenter");
      $("[data-screenshot]", $block).on("mouseenter", function () {
        var image_url = $(this).attr("data-screenshot");
        // Sets a higher zindex for the new image
        var z_index = $(".features__screenshot").length;

        // Append a new screenshot
        $.each($screen, function () {
          $(this).append('<div class="features__screenshot" style="display:none;background-image:url(\'' + image_url + "');z-index:" + z_index + ';"></div>');
        });

        // Gets all the other screenshots below the last one
        var other_screenshots = $(".features__screenshot:not(:last-child)");

        // Removes previous images
        $(".features__screenshot:last-child").fadeIn("fast", function () {
          other_screenshots.remove();
        });
      });
    }
  },

  housetour: function housetour() {
    $(".cd-single-point").children("a").on("click", function () {
      var selectedPoint = $(this).parent("li");
      if (selectedPoint.hasClass("is-open")) {
        selectedPoint.removeClass("is-open").addClass("visited");
      } else {
        selectedPoint.addClass("is-open").siblings(".cd-single-point.is-open").removeClass("is-open").addClass("visited");
      }
    });
    //close interest point description
    $(".cd-close-info").on("click", function (event) {
      event.preventDefault();
      $(this).parents(".cd-single-point").eq(0).removeClass("is-open").addClass("visited");
    });
  },

  tabscarousel: function tabscarousel() {
    var numSlick = 0;
    $(".tabscarousel").each(function () {
      var _$$addClass$slick;

      var tabID = $(this).attr("tab-id");
      var tabIndex = $(this).attr("tab-index");
      var tabcarouselID = tabID + "-" + tabIndex;
      // console.log(tabcarouselID);

      $(this).addClass("slider-" + tabcarouselID).slick((_$$addClass$slick = {
        appendDots: ".tabspaging-" + tabcarouselID,
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1
      }, _defineProperty(_$$addClass$slick, "slidesToShow", 1), _defineProperty(_$$addClass$slick, "fade", true), _defineProperty(_$$addClass$slick, "cssEase", "linear"), _defineProperty(_$$addClass$slick, "arrows", true), _defineProperty(_$$addClass$slick, "prevArrow", ".nav-prev-" + tabcarouselID), _defineProperty(_$$addClass$slick, "nextArrow", ".nav-next-" + tabcarouselID), _defineProperty(_$$addClass$slick, "autoplay", false), _$$addClass$slick));
    });
  },

  appSlickCaseStudies: function appSlickCaseStudies() {
    var _$$slick;

    $("#slick-case-studies").slick((_$$slick = {
      appendDots: "#testimonialpaging",
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      cssEase: "linear",
      centerMode: true,
      centerPadding: "25%"
    }, _defineProperty(_$$slick, "slidesToShow", 1), _defineProperty(_$$slick, "responsive", [{
      breakpoint: 1300,
      settings: {
        centerPadding: "18%"
      }
    }, {
      breakpoint: 960,
      settings: {
        centerPadding: "5%"
      }
    }, {
      breakpoint: 768,
      settings: {
        centerPadding: "3%"
      }
    }, {
      breakpoint: 600,
      settings: {
        centerPadding: "1.5%"
      }
    }]), _$$slick));
  },

  productImageGallery: function productImageGallery() {
    var _$$slick2;

    $("#productGallery").slick((_$$slick2 = {
      appendDots: "#productGallerypaging",
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1
    }, _defineProperty(_$$slick2, "slidesToShow", 1), _defineProperty(_$$slick2, "fade", true), _defineProperty(_$$slick2, "cssEase", "linear"), _defineProperty(_$$slick2, "autoplay", true), _defineProperty(_$$slick2, "autoplaySpeed", 5000), _defineProperty(_$$slick2, "pauseOnHover", false), _$$slick2));
  },

  fullscreenCarousel: function fullscreenCarousel() {
    $('.fullscreenCarousel').each(function (key, item) {

      var carouselID = $(this).attr("data-carousel");
      console.log(carouselID);

      var sliderId = '#' + carouselID;
      console.log(sliderId);

      $(sliderId).slick({
        appendDots: ".thumbpaging-" + carouselID,
        dots: true,
        infinite: false,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        fade: true,
        cssEase: "linear",
        autoplay: false,
        prevArrow: ".full-btn-prev-" + carouselID,
        nextArrow: ".full-btn-next-" + carouselID,
        customPaging: function customPaging(slick, index) {
          var $slickImg = $(slick.$slides[index]).find(".thumb-img"),
              imgPath = $slickImg.data("lazy"),
              textContent = $slickImg.attr("alt");
          return '<button class="custom-thumb"><div class="thumb"><img src=' + imgPath + ' /></div><p>' + textContent + '</p></button>';
        }
      });
    });
  },

  generalslideshow: function generalslideshow() {
    console.log("hello world");
    $(".banners").each(function () {
      var _$$addClass$slick2;

      var carouselID = $(this).attr("banner-id");
      $(this).addClass("banner-" + carouselID).slick((_$$addClass$slick2 = {
        appendDots: ".bannerpaging-" + carouselID,
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1
      }, _defineProperty(_$$addClass$slick2, "slidesToShow", 1), _defineProperty(_$$addClass$slick2, "fade", true), _defineProperty(_$$addClass$slick2, "cssEase", "linear"), _defineProperty(_$$addClass$slick2, "autoplay", true), _defineProperty(_$$addClass$slick2, "autoplaySpeed", 5000), _defineProperty(_$$addClass$slick2, "pauseOnHover", false), _$$addClass$slick2));
    });
  },

  producttourcarousel: function producttourcarousel() {
    $(".feat_carousel").slick({
      appendDots: ".pro-tour-paging",
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      fade: true,
      cssEase: "linear",
      autoplay: false
    });
  },

  itemsCarousel: function itemsCarousel() {
    $(".item_carousel").slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: "#item-btn-next",
      prevArrow: "#item-btn-prev",
      cssEase: "linear",
      responsive: [{
        breakpoint: 1000,
        settings: {
          nextArrow: "#item-btn-next",
          prevArrow: "#item-btn-prev",
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 760,
        settings: {
          nextArrow: "#item-btn-next",
          prevArrow: "#item-btn-prev",
          rows: 2,
          speed: 300,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }]
    });
  },

  planToggle: function planToggle() {
    $('.link.tab1, .link.tab2').click(function () {
      var tabs = $('.link.tab1, .link.tab2');
      var tabsToggle = $('.package_option.tab1, .package_option.tab2');
      var redirect = $(this).data("redirect");
      var cart_frm = $(".packages-frm");
      console.log(redirect);
      if (!$(this).hasClass('active')) {
        tabs.removeClass('active');
        $(this).addClass('active');
        tabsToggle.toggle();
      }

      cart_frm.attr("data-redirect", redirect);
      $('#variant_sel [data-opt="' + $(this).data("opt") + '"]').prop('selected', true).trigger('change');
    });
  },
  checkbox: function checkbox() {
    $(".checkbox-wrap-item").click(function () {
      var id = $(this).attr("id").replace("checkbox-option-", "");
      var selected = $("#checkbox-option-" + id);
      if (selected.length == 1) {
        var input = selected.find("input");
        var type = input.attr("type");
        if (type == "checkbox") {
          if (selected.hasClass("selected") == true) {
            selected.removeClass("selected");
            input.prop("checked", false);
          } else {
            selected.addClass("selected");
            input.prop("checked", "checked");
          }
        } else if (type == "radio") {
          var radio_sel = $(".rad_sel");
          radio_sel.prop("checked", false);
          $(".radio-items").removeClass("selected");
          input.prop("checked", "checked");
          $("#checkbox-option-" + id).addClass("selected");
        }
      }
    });
  },

  btnPhoneSelect: function btnPhoneSelect() {
    $(".country").click(function () {
      var dropdown = $(".country-options");
      if (dropdown.hasClass("is-open")) {
        dropdown.removeClass("is-open");
      } else {
        dropdown.addClass("is-open");
      }
    });

    $(".country-opt").click(function () {
      var changenumber = $(".nav-phone");
      var changecountry = $(".country-shortcode");
      var number = $(this).attr("data-number");
      var numberlink = $(this).attr("data-number-href");
      var country = $(this).attr("data-country");

      //console.log(country + ", -" + number);

      changenumber.html(number);
      changenumber.attr("href", numberlink);
      changecountry.html(country);

      var dropdown = $(".country-options");
      if (dropdown.hasClass("is-open")) {
        dropdown.removeClass("is-open");
      } else {
        dropdown.addClass("is-open");
      }
    });
  },

  addToCartSingle: function addToCartSingle() {
    var cart_frm = $(".cart-frm");
    cart_frm.each(function () {
      $(".variant_sel").on("change", function (key, row) {
        var selected = $(this).val();
        cart_frm.attr("action", "/!/Shopify/cart/" + selected + "/");
        cart_frm.attr("variant", selected);
      });

      $(this).on("submit", function (e) {
        e.preventDefault();
        var cart_frm = $(this);

        var qty = cart_frm.attr("quantity");
        var product_variant = cart_frm.attr("variant");

        cart_frm.attr("action", "/!/Shopify/cart/" + product_variant + "/" + qty);

        $.ajax({
          url: cart_frm.attr("action"),
          type: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: $(this).serialize(),
          success: function success(data, textStatus, jQxhr) {
            var basket = $("#basket-nav");
            var counter = basket.attr("data-counter");
            counter = parseInt(counter);
            // I need to replace this with the qty getting added.
            counter += parseInt(qty);

            basket.attr("data-counter", counter);
            $.fancybox.open({
              type: "html",
              src: '<div class="fc-content frm-dialog">' + '<div class="tabs-title ">' + '<h2 class="general-heading"><span>Added To Basket</span></h2>' + "</div>" + '<div class="dialog-item-inner text-center">' + "<p>View your basket, checkout now or continue browsing to see what else GoKonnect have to offer.</p>" + "</div>" + '<div class="btm justify-center flex flex-row">' + '<a class="button hidearrow w-auto inline-block mr-6" href="/basket">Go to Basket</a>' + "</div>" + "</div>"
            });
          },
          error: function error(jqXhr, textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      });
    });
    // Listen for change on variant Menu
  },

  addToCartPackage: function addToCartPackage() {
    var cart_frm = $(".packages-frm");
    cart_frm.each(function () {
      $("#variant_sel").on("change", function (key, row) {
        var selected = $(this).val();
        cart_frm.attr("action", "/!/Shopify/cart/" + selected + "/");
        cart_frm.attr("variant", selected);
      });

      $(this).on("submit", function (e) {
        e.preventDefault();
        $('body').addClass('loading');
        var cart_frm = $(this);
        var redirect = cart_frm.attr("data-redirect");
        var qty = 1;
        var product_variant = cart_frm.attr("variant");
        cart_frm.attr("action", "/!/Shopify/cart/" + product_variant + "/" + qty);

        $.ajax({
          url: cart_frm.attr("action"),
          type: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: $(this).serialize(),
          success: function success(data, textStatus, jQxhr) {
            var basket = $("#basket-nav");
            var counter = basket.attr("data-counter");
            counter = parseInt(counter);
            // I need to replace this with the qty getting added.
            counter += parseInt(qty);

            basket.attr("data-counter", counter);

            window.location = redirect;
          },
          error: function error(jqXhr, textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      });
    });
    // Listen for change on variant Menu
  },

  basket: function basket() {
    var remove_frm = $(".remove-basket-frm");
    $.each(remove_frm, function (key, row) {
      $(row).on("submit", function (e) {
        e.preventDefault();
        var action = $(row).attr("action");
        var data = $(this).serialize();
        var response = confirm("Are you sure you want to remove this item from your cart?");
        var variantId = action.split('/');
        variantId = variantId[variantId.length - 1];
        var total = 0;
        var basket = $("#basket-nav");
        var qty = 0;
        if (response == true) {
          $('body').addClass('loading');
          $.ajax({
            url: action,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            data: data,
            success: function success(_success, textStatus, jQxhr) {
              $('#item_' + variantId).slideUp().remove();
              $.ajax({
                url: '/!/Shopify/cart',
                success: function success(data, textStatus, jQxhr) {
                  $.each(data, function (key, row) {
                    qty += row.quantity;
                    total += row.quantity * parseFloat(row.variant.price);
                  });
                  total = total.toFixed(2);
                  basket.attr("data-counter", qty);
                  $('#cart-subtotal-value').html(total);
                  $('body').removeClass('loading');
                }
              });
              //location.reload();
            },
            error: function error(jqXhr, textStatus, errorThrown) {
              console.log(errorThrown);
            }
          });
        }
      });
    });

    // check qty
    var qty = $(".cart-qty-input");
    qty.on("change", function (e) {

      $('body').addClass('loading');
      e.preventDefault();
      var added = 0;
      var url = "/basket";
      var data = $("#update-cart-frm").serialize();
      var basket = $("#basket-nav");
      var qty = 0;
      $.each($(".cart-qty-input"), function () {
        var country = app.getUrlParameter('country');
        var cart_frm = $(this);
        var qty = cart_frm.val();
        var variantId = cart_frm.attr("data-variantId");
        var previous = cart_frm.attr("data-quantity-item");
        var update_cart_frm = $("#update-cart-frm");
        var update_cart_frm_url = "/!/Shopify/cart/" + variantId + "/" + (qty - previous);
        if (country == 'uk') {
          update_cart_frm_url += '?country=uk';
        }
        update_cart_frm.attr("action", update_cart_frm_url);
        console.log('QTY: ', qty);
        $.ajax({
          url: update_cart_frm.attr("action"),
          type: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: data,
          success: function success(data, textStatus, jQxhr) {
            added++;
            cart_frm.attr("data-quantity-item", qty);
            if (added === $(".cart-qty-input").length) {
              var total = 0;
              var total_qty = 0;
              var cart_url = '/!/Shopify/cart';
              if (country === 'uk') {
                cart_url += '?country=uk';
              }
              $.ajax({
                url: cart_url,
                success: function success(data, textStatus, jQxhr) {
                  $.each(data, function (key, row) {
                    total_qty += row.quantity;
                    total += row.quantity * parseFloat(row.variant.price);
                  });
                  total = total.toFixed(2);
                  basket.attr("data-counter", total_qty);
                  $('#cart-subtotal-value').html(total);
                  $('body').removeClass('loading');
                }
              });
            }
          },
          error: function error(jqXhr, textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      });
    });

    $("#update-cart-btn").click(function (e) {
      $('body').addClass('loading');
      e.preventDefault();
      var added = 0;
      var url = "/basket";
      var data = $("#update-cart-frm").serialize();
      var basket = $("#basket-nav");
      var qty = 0;
      $.each($(".cart-qty-input"), function () {
        var cart_frm = $(this);
        var qty = cart_frm.val();
        console.log('QTY 2: ', qty);
        var variantId = cart_frm.attr("data-variantId");
        var previous = cart_frm.attr("data-quantity-item");
        var updated_cart_frm = $("#update-cart-frm");
        updated_cart_frm.attr("action", "/!/Shopify/cart/" + variantId + "/" + (qty - previous));
        $.ajax({
          url: updated_cart_frm.attr("action"),
          type: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: data,
          success: function success(data, textStatus, jQxhr) {
            added++;
            if (added === $(".cart-qty-input").length) {
              var total = 0;
              var total_qty = 0;
              $.ajax({
                url: '/!/Shopify/cart',
                success: function success(data, textStatus, jQxhr) {
                  $.each(data, function (key, row) {
                    total_qty += row.quantity;
                    total += row.quantity * parseFloat(row.variant.price);
                  });
                  total = total.toFixed(2);
                  basket.attr("data-counter", total_qty);
                  $('#cart-subtotal-value').html(total);
                  $('body').removeClass('loading');
                }
              });
            }
          },
          error: function error(jqXhr, textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      });
    });
  },

  variants: function variants() {
    var options_sel = $(".options_sel");
    var variant_sel = $("#variant_sel option");
    var cart_frm = $(".cart-frm");

    $.each(options_sel, function (key, row) {
      $(row).on("change", function () {
        var selected = $(".options_sel option:selected");
        var variant = "";
        $.each(selected, function (key, value) {
          var selected_value = $(value)[0].label;
          if (key > 0) variant += " / ";
          variant += selected_value;
        });

        var found = false;
        $.each(variant_sel, function (key, value) {
          var variant_value = $(value)[0].label;
          if (variant == variant_value) {
            $("#variant_sel").val($(value)[0].value);
            $(".variant_price_wrap").addClass("hidden");
            cart_frm.attr("action", "/!/Shopify/cart/" + $(value)[0].value + "/1");
            $("#variant_" + $(value)[0].value).removeClass("hidden");
            found = true;
          }
        });

        if (found === false) {
          $(".variant_price_wrap").addClass("hidden");
          $(".cart-frm").addClass("unavailable");
          $("#service-unavailable-wrap").removeClass("hidden");
        } else {
          $(".cart-frm").removeClass("unavailable");
          $("#service-unavailable-wrap").addClass("hidden");
        }
      });
    });
  },

  packagevariants: function packagevariants() {
    var options_sel = $(".options_sel");
    var variant_sel = $("#variant_sel option");
    var cart_frm = $(".packages-frm");

    $.each(options_sel, function (key, row) {
      $(row).on("change", function () {
        var selected = $(".options_sel option:selected");
        var variant = "";
        $.each(selected, function (key, value) {
          var selected_value = $(value)[0].label;
          if (key > 0) variant += " / ";
          variant += selected_value;
        });

        var found = false;
        $.each(variant_sel, function (key, value) {
          var variant_value = $(value)[0].label;
          if (variant == variant_value) {
            $("#variant_sel").val($(value)[0].value);
            $(".variant_price_wrap").addClass("hidden");
            cart_frm.attr("action", "/!/Shopify/cart/" + $(value)[0].value + "/1");
            $("#variant_" + $(value)[0].value).removeClass("hidden");
            found = true;
          }
        });

        if (found === false) {
          $(".variant_price_wrap").addClass("hidden");
          $(".packages-frm").addClass("unavailable");
          $("#service-unavailable-wrap").removeClass("hidden");
        } else {
          $(".packages-frm").removeClass("unavailable");
          $("#service-unavailable-wrap").addClass("hidden");
        }
      });
    });
  },

  buildbasket: function buildbasket() {

    var additional_devices = $('#additional-devices');
    var additional_device_clone = $('#additional-devices-clone');
    var currency = $('.currency').html();

    $('.product_card').each(function (key, row) {
      var selected = $(row);
      var product_detail = selected.find('.product-detail');
      if (product_detail.length > 0) {
        var parent = selected.closest('.product_card');
        var product_id = parent.attr('productid');
        var product_title = product_detail.find('h3').html();
        var product_price = product_detail.find('.price').val();
        var product_qty = selected.find('input.qty').val();

        if ($('#cart-item-' + product_id).length == 1) {
          $('#cart-item-' + product_id).remove();
        }

        if (product_qty > 0) {
          var html = additional_device_clone.clone();
          html = $(html);

          $(".buynow-summary-inner-wrap").removeClass("additional-devices-details_hide");

          html.attr('id', 'cart-item-' + product_id);
          html.removeClass('hidden');
          if (product_qty == 1) {
            html.find('.title').html(product_title);
          } else {
            html.find('.title').html(product_qty + " " + product_title);
          }
          html.find('.price').html(currency + product_price);
          additional_devices.append(html);
        }
      }
    });
  },

  buynow: function buynow() {
    var currency = $('.currency').html();
    $(".togglebase").click(function () {
      var selected = $(this);
      var val_input = $(this).closest(".product-detail").find(".qty");
      var parent = $(this).closest('.product_card');
      var product_id = parent.attr('productid');

      if ($(".togglebase").hasClass("disablebase")) {
        if (val_input.val() <= 0) {
          return;
        }

        val_input.val(parseInt(val_input.val() - 1));

        var price = $(this).closest(".product-detail").find(".price");
        var cost = $(this).closest(".product-detail").find(".cost");
        var quantity = $(this).closest(".product-detail").find(".cart-frm");

        cost.val((parseFloat(price.val()) * val_input.val()).toFixed(2));

        //update the qty attribute on the form element to send to shopify on submit;
        quantity.attr("quantity", val_input.val());

        $('#main-kit-item').remove();
        total_cost();
        //app.buildbasket();

        $(".togglebase").toggleClass("disablebase");
        $(".togglebase").text('Add Base Kit');
        $(".buynow-summary-inner-wrap").toggleClass("main_details_hide");
      } else {
        if (val_input.val() >= 1) {
          return;
        }

        val_input.val(parseInt(val_input.val()) + 1);
        var price = $(this).closest(".product-detail").find(".price");
        var cost = $(this).closest(".product-detail").find(".cost");
        var quantity = $(this).closest(".product-detail").find(".cart-frm");

        cost.val((parseFloat(price.val()) * val_input.val()).toFixed(2));

        //update the qty attribute on the form element to send to shopify on submit;
        quantity.attr("quantity", val_input.val());

        $(".buynow-summary-inner-wrap").toggleClass("main_details_hide");

        //var main_kit = $('#main-kit');
        //var main_kit_clone = $('#main-kit-clone');
        //var html = main_kit_clone.clone();
        //var product_price = selected.prev('.variant_price_wrap').find('input.price').val();
        //html.attr('id', 'main-kit-item');
        //html.find('.title').html("Smart Secure Kit");
        //html.find('.price').html(currency + product_price);
        //html.removeClass('hidden');
        //main_kit.append(html);

        total_cost();
        //app.buildbasket();
        $(".togglebase").toggleClass("disablebase").text('Remove Base Kit');
      }
    });

    $(".toggleinstallation").click(function () {
      var selected = $(this);
      var installation = selected.find('input[name="installation"]');
      $(".toggleinstallation").removeClass("active");
      installation.prop("checked", true);
      selected.addClass('active');
    });

    $(".toggle_package_options").click(function () {

      var selected = $(this);
      var cart_frm = $(".package-frm");
      var packageOption = selected.find('input[name="package_options"]');
      var redirect = $(this).data("redirect");
      $(".toggle_package_options").removeClass("active");
      packageOption.prop("checked", true);
      selected.addClass('active');
      $(".package_discount").toggleClass("show_discount");
      console.log("hello");
      console.log(redirect);

      cart_frm.attr("data-redirect", redirect);
    });

    $(".fa-minus").click(function () {
      var val_input = $(this).closest(".product-detail").find(".qty");

      if (val_input.val() <= 0) {
        return;
      }

      val_input.val(parseInt(val_input.val() - 1));

      var price = $(this).closest(".product-detail").find(".price");
      var cost = $(this).closest(".product-detail").find(".cost");
      var quantity = $(this).closest(".product-detail").find(".cart-frm");
      var title = $(this).closest(".product-detail").find("h3");

      cost.val((parseFloat(price.val()) * val_input.val()).toFixed(2));

      //update the qty attribute on the form element to send to shopify on submit;
      quantity.attr("quantity", val_input.val());
      //app.buildbasket();
      total_cost();
    });

    $(".fa-plus").click(function () {
      var val_input = $(this).closest(".product-detail").find(".qty");
      var maximum = $(this).closest(".product-detail").find(".qty").attr("maxqty");
      maximum = parseInt(maximum);
      if (val_input.val() >= maximum) {
        return;
      }

      val_input.val(parseInt(val_input.val()) + 1);

      var price = $(this).closest(".product-detail").find(".price");
      var title = $(this).closest(".product-detail").find("h3");
      var cost = $(this).closest(".product-detail").find(".cost");
      var quantity = $(this).closest(".product-detail").find(".cart-frm");

      cost.val((parseFloat(price.val()) * val_input.val()).toFixed(2));

      //update the qty attribute on the form element to send to shopify on submit;
      quantity.attr("quantity", val_input.val());
      //app.buildbasket();
      total_cost();
    });

    $(".qty-btn").click(function () {
      var val_input = $(this).closest(".product_card").find(".qty");
      var active_state = $(this).closest(".product_card");
      //alert(val_input.val());
      if (val_input.val() >= 1) {
        active_state.addClass("active");
      } else {
        active_state.removeClass("active");
      }
      app.buildbasket();
    });

    function total_cost() {
      setTimeout(function () {

        var total = 0;
        var active_state = $(".totalAmount");
        var buy_now = $('#buynow-summary');
        var base_price = 0;

        if ($(".togglebase").hasClass("disablebase")) {
          var base_price = $('.base_package_price').attr('value');
          console.log("checked if base package added");
        }

        $.each($(".cost"), function (index, value) {
          total += parseFloat($(value).val());
        });

        total = total.toFixed(2);
        $(".total").val(total);
        $(".price_total").text(total);

        var discount = $('.discount').attr('discount');
        var discountamount = parseFloat(base_price) * parseFloat(discount);
        var discountTotal = parseFloat(total) - parseFloat(discountamount);
        $(".discount_total").text(discountTotal.toFixed(2));

        if (total > 0) {
          buy_now.removeClass('hidden');
          active_state.addClass("active");
        } else {
          buy_now.addClass('hidden');
          active_state.removeClass("active");
        }
      }, 700);
    }
  },

  appSlickBuyNow: function appSlickBuyNow() {
    var shopSlick = 0;
    $(".slick-four").each(function () {
      var carouselID = $(this).attr("shop-carousel-id");
      $(this).addClass("shop-slider-" + carouselID).slick({
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: "linear",
        arrows: true,
        prevArrow: ".prev-btn-id-" + carouselID,
        nextArrow: ".next-btn-id-" + carouselID,
        autoplay: false,
        appendDots: ".product-paging-" + carouselID,
        dots: true,
        responsive: [{
          breakpoint: 1020,
          settings: {
            slidesToShow: 3

          }
        }, {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        }]
      });
    });
    $(".slick-three").each(function () {
      var carouselID = $(this).attr("shop-carousel-id");
      $(this).addClass("shop-slider-" + carouselID).slick({
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        cssEase: "linear",
        arrows: true,
        prevArrow: ".prev-btn-id-" + carouselID,
        nextArrow: ".next-btn-id-" + carouselID,
        autoplay: false,
        appendDots: ".product-paging-" + carouselID,
        dots: true
      });
    });
  },

  getUrlParameter: function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return _typeof(sParameterName[1]) === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  },

  buildPackage: function buildPackage() {
    var cart_frm = $(".package-frm");
    var country = app.getUrlParameter('country');
    console.log('GET PARAMS = ', country);

    // on submit
    cart_frm.on("submit", function (e) {
      $('body').addClass('loading');
      e.preventDefault();
      var url = "/shop/protection";
      var redirect = cart_frm.attr("data-redirect");
      if (country === 'uk') {
        url += '?country=uk';
      }
      var items = [];
      $(".cart-frm").each(function (key, row) {
        var cart_frm = $(this);
        var qty = cart_frm.attr("quantity");
        var product_variant = cart_frm.attr("variant");
        if (qty > 0) {
          items.push({ variant: product_variant, qty: parseInt(qty) });
        }
      });

      var installation = $('input[name="installation"]:checked').val();
      if (installation > 0) {
        items.push({ variant: installation, qty: 1 });
      }

      var package_options = $('input[name="package_options"]:checked').val();
      if (package_options > 0) {
        items.push({ variant: package_options, qty: 1 });
      }

      // AJAX REQUEST
      $.post("/!/Shopify/multicart", { items: items, country: country }).done(function (data) {
        window.location = redirect;
      });
    });
  },

  checkout_button: function checkout_button() {
    window.GTagCrossDomain = function () {
      var client_id = ga.getAll()[0].get('linkerParam');
      // console.log("client_id_checkout: " + client_id);
      var new_url = "/checkout?" + client_id;
      $(".checkout_button").each(function () {
        $('.checkout_button').attr('href', new_url);
      });
    };
  },

  init: function init() {
    app.mobileMenu();

    if ($("#mainnav").length > 0) {
      app.dropdown();
    }
    if ($("#checkbox").length > 0) {
      app.checkbox();
    }

    if ($("#cart-frm").length > 0) {
      app.addToCart();
      app.variants();
    }

    if ($(".cart-frm").length > 0) {
      app.addToCartSingle();
      app.variants();
    }

    if ($(".packages-frm").length > 0) {
      app.addToCartPackage();
      app.variants();
    }

    if ($("#basket").length > 0) {
      var currency = $('#basket').attr('currency');

      $.ajax({
        url: '/shopify-basket?currency=' + currency,
        success: function success(response) {
          $('#basket-ajax-items').html(response);
          app.basket();
        }
      });
    }

    if ($("#tabs").length > 0) {
      app.initTabs();
    }

    if ($("#appFeaturesCentered").length > 0) {
      app.appFeaturesCentered();
    }

    if ($("#slick-case-studies").length > 0) {
      app.appSlickCaseStudies();
    }

    if ($("#housetour").length > 0) {
      app.housetour();
    }

    if ($(".banners").length > 0) {
      app.generalslideshow();
    }

    if ($(".tabscarousel").length > 0) {
      app.tabscarousel();
    }

    if ($(".product-image-gallery").length > 0) {
      app.productImageGallery();
    }

    if ($(".fullscreenCarousel").length > 0) {
      app.fullscreenCarousel();
    }

    if ($(".item_carousel").length > 0) {
      app.itemsCarousel();
    }

    if ($(".quote_block").length > 0) {
      app.quoteBlock();
    }

    if ($(".product_tour_carousel").length > 0) {
      app.producttourcarousel();
    }

    if ($("#package_tab").length > 0) {
      app.planToggle();
    }

    if ($(".btn-phone-select").length > 0) {
      app.btnPhoneSelect();
    }

    if ($(".checkout_button").length > 0) {
      app.checkout_button();
    }

    if ($("#buynow").length > 0) {
      // AJAX SHOPIFY LOADS
      var targets = $('div[shopify-ajax]');
      app.appSlickBuyNow();
      $.each(targets, function (key, row) {
        var row = $(row);
        var shopify_id = row.attr('shopify-ajax');
        var country = row.attr('shopify-country');
        var currency = row.attr('currency');
        var productID = row.attr('productid');
        $.ajax({
          url: '/shopify-product?id=' + shopify_id + '&country=' + country + '&currency=' + currency + '&productid=' + productID,
          success: function success(response) {
            row.html(response);
            if (key === targets.length - 1) {
              app.buynow();
              app.buildPackage();
            }
          }
        });
      });

      //
    }
  }
};

// $(document).ready(function () {
//   var $tabs = $('.horizontalTab');
//
//   $tabs.responsiveTabs({
//     rotate: false,
//     startCollapsed: 'accordion',
//     collapsible: 'accordion',
//     setHash: true,
//     click: function (e, tab) {
//       $('.info').html('Tab <strong>' + tab.id + '</strong> clicked!');
//     },
//     activate: function (e, tab) {
//       $('.info').html('Tab <strong>' + tab.id + '</strong> activated!');
//     },
//     activateState: function (e, state) {
//       //console.log(state);
//       $('.info').html('Switched from <strong>' + state.oldState + '</strong> state to <strong>' + state.newState + '</strong> state!');
//     }
//   });
// });


$(document).ready(function () {
  app.init();
  window.GTagCrossDomain();
});

$(window).scroll(function () {
  var op = $(document).height() - $(window).height() - $(window).scrollTop();
  // console.log(op);
  if (op <= 600) {
    $(".total_float").addClass("fadeoutprice");
    $(".total_float").removeClass("showtotal");
  } else {
    $(".total_float").addClass("showtotal");
    $(".total_float").removeClass("fadeoutprice");
  }
});

window.GTagCrossDomain = function () {
  var client_id = ga.getAll()[0].get('linkerParam');
  console.log("client_id1: " + client_id);

  $('body').on('click', '.checkout_button', function (e) {
    var url = "/checkout";
    //GA tracking code to cart URL for tracking of session 
    var client_id = ga.getAll()[0].get('linkerParam');
    console.log("client_id2: " + client_id);
    if (client_id.length > 0) {
      console.log("client_id3: " + client_id);
      //need to modify the base shopify url i think?
      url += '&_ga=' + client_id;
    }
    window.open(url, '_self');
  });
};
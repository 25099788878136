"use strict";

console.log("LOAD JS");

$('.tabbedcarousels').each(function () {
  var tabID = $(this).attr("tab-id");
  console.log('LOAD UNQUITE = ', tabID);
  var carouselnav = $(".tabcarouselnav-" + tabID);
  var carouseldetail = $(".tabcarouseldetail-" + tabID);

  var numTabs = carouselnav.attr("tab-count");

  console.log('LOAD UNQUITE selector = ', ".tabcarouselnav-" + tabID);
  console.log('LOAD UNQUITE nav = ', carouselnav);
  console.log('LOAD UNQUITE carouseldetail = ', carouseldetail);

  carouselnav.slick({
    rows: 0,
    arrows: false,
    draggable: true,
    useTransform: false,
    slidesToShow: numTabs,
    slidesToScroll: 1,
    cssEase: "linear",
    variableWidth: false,
    infinite: false,
    centerMode: false,
    prevArrow: ".btn-prev-123",
    nextArrow: ".btn-next-123",
    asNavFor: carouseldetail,
    responsive: [{
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        variableWidth: false,
        infinite: true,
        centerMode: true,
        centerPadding: "1.5%"
      }
    }]
  });

  carouseldetail.slick({
    rows: 0,
    useTransform: false,
    draggable: false,
    fade: true,
    asNavFor: carouselnav
  });

  $(".tabcarouselnav .item").on("click", function () {
    var index = $(this).attr("data-slick-index");
    carouseldetail.slick("slickGoTo", index);
  });
});